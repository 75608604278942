<template>
  <section class="home-content">
    <div class="container">
      <h1>Nastavení</h1>
      <div class="user-settings">
        <form class="form-label form-js-label" @submit.prevent="submit">
         <!-- <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="username" type="text" name="username" v-model="username" required>
                <label for="username" :class="username ? 'Yes' : 'No'">E-mail</label>
                <img src="../assets/images/ico-mail.png" alt="mail">
            </fieldset>
          </div> !-->
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="firstname" type="text" name="firstname" v-model="firstname" required>
                <label for="firstname" :class="firstname ? 'Yes' : 'No'">Jméno</label>
            </fieldset>
          </div>
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="secondname" type="text" name="secondname" v-model="secondname" required>
                <label for="secondname" :class="secondname ? 'Yes' : 'No'">Příjmení</label>
            </fieldset>
          </div>
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
              <select class="uk-select" v-model="selected" id="my-select">
                <option v-for="option in options" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
              <label for="my-select" :class="selected ? 'Yes' : 'No'"> Kontakt </label>
            </fieldset>
          </div>
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="phone" type="text" name="phone" v-model="phone" disabled>
                <label for="phone" :class="phone ? 'Yes' : 'No'">Telefon</label>
            </fieldset>
          </div>
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="street" type="text" name="street" v-model="street" required>
                <label for="street" :class="street  ? 'Yes' : 'No'">Ulice</label>
            </fieldset>
          </div>
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="streetnumber" type="text" name="streetnumber" v-model="streetnumber" required>
                <label for="streetnumber" :class="streetnumber ? 'Yes' : 'No'">Číslo popisné</label>
            </fieldset>
          </div>
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="city" type="text" name="city" v-model="city" required>
                <label for="city" :class="city ? 'Yes' : 'No'">Obec</label>
            </fieldset>
          </div>
          <div class="row">
            <fieldset :class="{error: showError, succes: showSucces}">
                <input id="psc" type="text" name="psc" v-model="psc" required>
                <label for="psc" :class="psc ? 'Yes' : 'No'">PSČ</label>
            </fieldset>
          </div>
          <div class="row end">
              <button class="button" v-on:click="submit">Uložit změny</button>
          </div>
      </form>
      </div>
    </div>
  </section>
   <p v-if="showError" id="error" :class="{notification: showError, notificationSucces: showSucces}">{{msg}}</p>
</template>

<script>
import { mapGetters} from "vuex";

export default {
  name: "user",
  token: "",
  computed: {
    ...mapGetters([
      'getId',
      'getDetails',
      'getEmail'
    ])
  },

  created() {
    let user = this.getDetails
    this.username = this.getEmail;
    this.firstname = user.jmeno;
    this.secondname = user.prijmeni;
    this.selected = user.kontakt;
    this.phone = user.telefon;
    this.street = user.ulice;
    this.streetnumber = user.cp;
    this.city = user.obec;
    this.psc = user.psc;
  },
  data() {
    return {
      form: {
        show: true,
      },
      username: "",
      firstname: "",
      secondname: "",
      phone: "",
      street: "",
      streetnumber: "",
      city: "",
      psc:"",
      selected: "",
         options: [
            { text: 'Chci zájemce kontaktovat sám', value: '1' },
            { text: 'Chci aby zájemci kontaktovali mě', value: '2' },
          ],
      msg: "",
      showError: false,
      showSucces: false,
    };
  },
  methods: {
    async submit() {
        if( !this.validate() ){
          this.msg = 'Vyplňte, prosím,  všechna pole';
          this.showError = true;
          this.showSucces = false;
          return;
        }

        await this.$store.dispatch('getToken').then((token) => this.token = token);

        let data =  {
          "id": this.getId,
          "token": this.token,
          "user":
            {
              "email": this.username,
              "jmeno": this.firstname,
              "prijmeni": this.secondname,
              "kontakt": this.selected,
              "telefon": this.phone,
              "ulice": this.street,
              "cp": this.streetnumber,
              "obec": this.city,
              "psc": this.psc,
            }
        };

        await this.$store.dispatch("setDetails", data).then( response => {
          if( response.data.status === 'OK' ) {
            this.msg = "Vaše údaje jsme úspěšně uložili";
            this.showSucces = true;
            this.showError = true;
          }else{
            this.msg = response.data.info.name;
            this.showError = true;
            this.showSucces = false;
          }
        });
    },
    validate(){
      let valid = true;
      if( !this.firstname || !this.secondname || !this.selected || !this.phone || !this.street || !this.streetnumber || !this.city || !this.psc ){
        valid = false;
      }

      return valid;
    }
  }
}
</script>
<style lang="less" scoped>
.user-settings{
  margin-top: 4rem;
  form{
    .row{
        margin-bottom: 3rem;
        fieldset{
            max-width: 30rem;
            position: relative;
            margin: auto;
            input{
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
                    align-items: center;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
            padding: 0 2rem;
            width: 100%;
            height: 4rem;
            border: 1px solid #D6D6D6;
            -webkit-border-radius: 0.5rem;
            -moz-border-radius: 0.5rem;
            border-radius: 0.5rem;
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.1rem;
            color:#7C7C7B;
            &.error{
              border-color: #AC2F2F;
              color: #AC2F2F;
            }
            &.succes{
              border-color: #04ac66;
            }
          }
          label{
            &.Yes{
              font-size: 12px;
              -webkit-transform: translate3d(0, -3rem, 0);
                      transform: translate3d(0, -3rem, 0);
              border: 1px solid #b8b8b8;
              padding: 3px;
              background:#fff;
              border-radius: 3px;
            }
          }
          >span.error{
            position: absolute;
            top: 0.5rem;
            right: 1.1rem;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: #AC2F2F;
            background: #fff;
            z-index: 2;
            height: 3rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
                    align-items: center;
          }
            img{
                position: absolute;
                top: 0;
                right: 1.5rem;
                bottom: 0;
                margin: auto;
            }
            &.error{
              select{
                border-color: #AC2F2F;
              }
              input{
              border-color: #AC2F2F;
              }
              label{
              border-color: #AC2F2F;
              color: #AC2F2F;
              }
            }
            &.succes{
              select{
                border-color: #04ac66;
              }
              input{
                border-color: #04ac66;
              }
              label{
                border-color: #04ac66;
                color: #04ac66;
              }
            }
        }
        &.end{
            margin-top: 6.3rem;
            button{
                margin:auto;
            }
        }
      .uk-select{
        position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 2rem;
          width: 100%;
          max-width: 30rem;
          margin:auto;
          height: 4rem;
          border: 1px solid #D6D6D6;
          -webkit-border-radius: 0.5rem;
          -moz-border-radius: 0.5rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 2.1rem;
          color:#7C7C7B;
      }  
    }
  }
}

</style>